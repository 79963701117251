var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length != 0
    ? _c(
        "section",
        { staticClass: "clearfix" },
        [
          _c("div", { staticClass: "mt-2" }, [_vm._t("default")], 2),
          _vm._l(_vm.items, function (item, index) {
            return _c("div", { key: index, staticClass: "sdk-header-item" }, [
              _c("div", { staticClass: "information" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "label",
                    attrs: { title: _vm.getLabel(item) },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.getLabel(item)) +
                        "\n          "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "root",
                    attrs: { title: _vm.getExtensionValue(item) },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.getRootName(item)) +
                        ":  " +
                        _vm._s(_vm.getExtensionValue(item)) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm.showAddRemove
                ? _c("div", { staticClass: "remove" }, [
                    _c("i", {
                      staticClass: "fal fa-trash-alt pr-2 clickable",
                      on: {
                        click: function ($event) {
                          return _vm.removeItem(index)
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ])
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }